import { useState, useEffect, useContext } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { LanguageContext } from '../../../contexts/LanguageContext';
import { DeviceContext } from '../../../contexts/DeviceContext';
import { ArrowRepeat, ArrowDownUp, Phone, ArrowClockwise } from 'react-bootstrap-icons';

import mobileLabo from '../../../assets/images/divers/mobileLabo.png';
import mobileViewport from '../../../assets/images/divers/mobileViewport.png';

const MobileModal = ({onClose}) => {

    const { language } = useContext(LanguageContext);
    const { mobile } = useContext(DeviceContext);

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (mobile) {
            setShow(true);
        }
    }, [mobile]);

    const handleClose = () => {
        setShow(false);
        if (onClose) {onClose(); }
    }; 

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Phone className="me-2"/>
                    {{'EN': 'Mobile Version', 'FR': 'Version Mobile'}[language]}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                {{
                    'EN': <>
                        <p className='mt-3'>
                            You are currently using the mobile version of the webapp.  
                            You can still acess the service, but I highly recommend using a desktop or laptop computer for the best experience! 
                        </p>
                    </>,
                    'FR': <>
                        <p className='mt-3'>
                            Vous utilisez actuellement la version mobile de l'application web.  
                            Vous pouvez toujours accéder au service, mais je vous recommande d'utiliser un ordinateur pour une meilleure expérience! 
                        </p>
                    </>
                }[language]}
                <div className='d-flex flex-row' style={{textAlign: "center", postition: "relative"}}>
                    <ArrowRepeat style={{position: "absolute", textAlign: "center", top: "60%", left: "40%", width: "20%"}} size={30}/>
                    <div className='d-flex flex-column w-50 justify-center'>
                        <img className="w-100" src={mobileLabo}/>
                        <div style={{fontWeight: "bold"}}>{{'EN': 'Settings', 'FR': 'Paramètres'}[language]}</div>
                        <div style={{fontStyle: "italic", fontSize: "0.8em"}}>
                            {{
                                'EN': 'To change the mosaic parameters', 
                                'FR': 'Pour changer les paramètres de la mosaïque'
                            }[language]} 
                        </div>
                    </div>
                    <div className='d-flex flex-column w-50 justify-center'>
                        <img className="w-100" src={mobileViewport}/>
                        <div style={{fontWeight: "bold"}}>{{'EN': 'Viewport', 'FR': 'Résultat'}[language]}</div>
                        <div style={{fontStyle: "italic", fontSize: "0.8em"}}>
                            {{
                                'EN': 'To view the results, save, add to cart.', 
                                'FR': 'Pour voir les résultats, sauvegarder, ajouter au panier...'
                            }[language]} 
                        </div>
                    </div>
                </div>
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {{'EN': 'Understood', 'FR': 'OK'}[language]}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MobileModal;