
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Button from 'react-bootstrap/Button';

import Dice from '../../../../assets/svg/Dice.svg'
import DiceDark from '../../../../assets/svg/DiceDark.svg'

const RandomGenToggle = ({eventKey, onToggle, randomGen}) => {

    //const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, () => onToggle && onToggle(eventKey));

  return (
    <Button
        className={randomGen ? "" : "control-run__activate-random"}
        onClick={decoratedOnClick}
        variant={randomGen ? "outline-secondary" : "outline-light"}
        size = "sm" 
    >
      {randomGen ? "Close" : 
      <img className="w-100" 
        src={Dice} alt="Dice Control" 
        style={{width: "2em", height: "2em"}}
      />}
    </Button>
  );
}

export default RandomGenToggle; 