import { useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { QuestionSquareFill, Dice5Fill, EmojiSmileUpsideDownFill, PenFill, LayoutWtf, RocketTakeoffFill, EyeFill} from 'react-bootstrap-icons';

const HelpButton = () => {
    const { language } = useContext(LanguageContext);
    const [showModal, setShowModal] = useState(false);

    const handleHelpClicked = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const eng = <>
       <h4 className='mt-3 mb-2'> <EmojiSmileUpsideDownFill className='me-2'/>Welcome</h4>
        <p>
            <strong>Welcome</strong> to my Web App! This online interface lets you interact with my mosaic-making algorithm to create colorful, customized mosaics.
            <strong> This is not AI</strong>, but a big piece of generative code that turns the parameters you select into posters. 
        </p>
        <p>
            Experiment with different parameters and click <strong>Generate</strong><RocketTakeoffFill className='ms-2'/> to create a poster based on your choices! 
            <strong> No need to fill out everything at once! </strong>
            Feel free to go back and forth between the output and the parameters to refine and improve your work.
        </p>
        <p>
            Happy making!
        </p>

        <hr/>

        <h4 className='mt-4 mb-2'><PenFill className='me-2'/>Editing Modes</h4>
        <p>
            <strong>Abstract mode</strong> lets you create abstracts mosaics, while <strong> Portrait mode </strong> transforms any image into a mosaic-style portrait. 
            You can select one image from the image library or use your own pic (please use a picture with a uniform background).
        </p>
        <hr/>

        <h4 className='mt-4 mb-2'> <Dice5Fill className='me-2'/>Random Mode</h4>
        <p>
            The <strong>Random mode</strong> helps you generate a unique poster by creating a random set of parameters. 
            Every seed leads to a unique output, try to generate with different seeds to see what the algorithm can create!
        </p>
        <hr/>

        <h4 className='mt-4 mb-2'> <EyeFill className='me-2'/>Viewport</h4>
        <p>
            The viewport is the area where your creation is displayed. 
            You can save different versions of your mosaik by clicking on the <strong>Save</strong> button. 
            Try using the <strong>Mockup View</strong> to see what your creation looks like as a poster!
        </p>
        
    </>

    const fr = <>

        <h4 className='mt-3 mb-2'> <EmojiSmileUpsideDownFill className='me-2'/>Bienvenue</h4>
        <p>
            <strong>Bienvenue</strong> sur mon app! Cette interface en ligne permet d'interagir avec mon algo de mosaïques génératives pour créer des images colorées et personnalisées.
            <strong> Ce n'est pas de l'IA</strong>, c'est le résultat de tout un tas de fonctions qui transforments les paramètres que vous sélectionnez en mosaiques.
        </p>
        <p>
            Expérimentez avec différents paramètres et cliquez sur <strong>Générer</strong><RocketTakeoffFill className='ms-2'/> pour créer un poster à partir de vos choix !
            <strong> Pas besoin de tout remplir d'un coup ! </strong>
            N'hésitez pas à faire des allers-retours entre les paramètres et le résultat pour affiner et améliorer votre mosaique.
        </p>
        <p>
            Amusez vous bien!
        </p>

        <hr/>

        <h4 className='mt-4 mb-2'><PenFill className='me-2'/>Modes d'édition</h4>
        <p>
            Le mode <strong>abstrait</strong> vous permet de créer des mosaïques abstraites, tandis que le mode <strong>portrait</strong> transforme n'importe quelle photo en portrait-mosaïque.
            Vous pouvez utiliser votre propre photo ou en sélectionner une dans la bibliothèque d'images (utilisez de préference des images dont le fond est uni pour faciliter la tâche de l'algorithme).
        </p>
        <hr/>

        <h4 className='mt-4 mb-2'> <Dice5Fill className='me-2'/>Mode aléatoire</h4>
        <p>
            Le mode <strong>aléatoire</strong> vous permet de générer un poster unique à partir d'un ensemble de paramètres séléctionnés aléatoirement à partir de la seed (graine).
            Chaque <strong>seed</strong> mène à un résultat unique, essayez de générer avec différentes seed pour voir ce que l'algorithme peut créer !
        </p>
        <hr/>

        <h4 className='mt-4 mb-2'> <EyeFill className='me-2'/>Viewport</h4>
        <p>
            Le <strong>Viewport</strong> est l'endroit où votre création est affichée.
            Vous pouvez enregistrer différentes versions de votre mosaïque avec <strong>Enregistrer</strong>.
            Essayez d'utiliser la <strong>Mockup View</strong> pour voir comment votre création rend en poster ! 
        </p>


    </>
        

    return (
        <>
            <div
                className='text-with-icon ms-2 button-text'
                style={{ color: 'var(--color-1)' }}
                onClick={handleHelpClicked}
            >
                {{ EN: 'Help', FR: 'Aide' }[language]}
                <QuestionSquareFill className='mx-2' />
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-with-icon ms-2'>
                        {{ EN: 'Help', FR: 'Aide' }[language]}
                        <QuestionSquareFill className='mx-2' />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{maxHeight: "50vh", overflowY: "auto"}}>
                    {{'EN': eng, 'FR': fr}[language]}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default HelpButton;